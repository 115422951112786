import Web3 from 'web3';
import { useWeb3React } from "@web3-react/core";
import { injectedProvider } from "./wallet-connector";
import { Web3ReactProvider } from '@web3-react/core';
import Minecart from './minecart.js';
import './App.css';

function getLibrary(provider, connector) {
  return new Web3(provider);
}

function App() {
  return (
  	<Web3ReactProvider getLibrary={getLibrary}>
    	<div className="App">
      		<Minecart></Minecart>
    	</div>
    </Web3ReactProvider>
  );
}

export default App;
