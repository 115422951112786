import axios from "axios";
import Web3 from 'web3';
import { useState } from 'react';
import { useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import { injectedProvider } from "./wallet-connector";
import contract1ABI from './contract1ABI.json';
import contract2ABI from './contract2ABI.json';
import WOW from 'wowjs';
import minecart from './minecart.png';
import footerlogo from './footerlogo.png';

require('dotenv').config();

const Minecart = () => {

  useEffect(() => {
      new WOW.WOW({
      live: true
    }).init();
      FetchOrderCount2(); 
      document.getElementsByClassName('menu')[0].style.height= '0px';
       }, []);

  var {
    account,
    activate,
    active,
    chainId,
    connector,
    deactivate,
    error,
    provider,
    setError,
    } = useWeb3React();

    var accounts = [];

    const [currentAccount, setCurrentAccount] = useState('');

    const connect = async () => {
      if(orderCount2==="" && orderCount===""){showAlert0();}
      else {
      if (typeof window.ethereum !== 'undefined') {
          console.log('MetaMask is installed!');
          await activate(injectedProvider);
          var { provider } = await injectedProvider.activate();
          var web3 = new Web3(provider); 
          //document.getElementsByClassName('load_icon')[0].style.display= 'block';
          if(typeof window.ethereum!=='undefined' && active) {alert("MetaMask Connected")}
          if(checkCurrentNetwork()!=='0xa573') {await switchNetwork('0xa573').then(()=>{}).catch(()=>{});}
          const contract1Address= '0x731d3a872d80297C741fFcC4f1A0CF58D3f314BD';
          const contract2Address = '0x6B13E7b17767ed51621e8F55eD7774F2B3854A7F';
          const CONTRACT_1 = await new web3.eth.Contract(contract1ABI, contract1Address);
          const CONTRACT_2 = await new web3.eth.Contract(contract2ABI, contract2Address); 
          accounts = await web3.eth.getAccounts();
          //document.getElementsByClassName('load_icon')[0].style.display= 'none';
          document.getElementById('selltab').style.display="none";
          document.getElementsByClassName("tablinks")[0].className = document.getElementsByClassName("tablinks")[0].className.replace("tablinks","tablinks active-tab");
          const interval = setInterval(() => {
             fetchOrderList().catch((err)=>{});
             fetchOrderList2().catch((err)=>{});
             console.log('data fetched');
          }, 80000);

          await getBalances();
          setCurrentAccount(accounts[0]);
        
      }

      else alert("install MetaMask");
    }
    
  }

    var arrayDataItems;
    var arrayDataItems2;
    const [arrayItems, setArrayItems] = useState([]);
    const [arrayItems2, setArrayItems2] = useState([]);

    const [arrayItems3, setArrayItems3] = useState([]);
    const [arrayItems4, setArrayItems4] = useState([]);
    const [arrayItems5, setArrayItems5] = useState([]);
    const [arrayItems6, setArrayItems6] = useState([]);

    const [orderCount, setOrderCount] = useState('');
    const [orderCount2, setOrderCount2] = useState('');
    const [alertContent, setAlertContent] = useState('');
    const [alertContent1, setAlertContent1] = useState('');
    const [alertContent2, setAlertContent2] = useState('');

    const [balance, setBalance] = useState('');
    const [balance1, setBalance1] = useState('');

    const getBalances = async()=> {
          const ethers = require("ethers");
          const provider = new ethers.JsonRpcProvider("https://bsc-dataseed.binance.org/");    
          const provider1 = new ethers.JsonRpcProvider("https://rpc2.goldxscan.com/");   
          const balance = await provider.getBalance(accounts[0]);
          const balance1 = await provider1.getBalance(accounts[0]);   
          console.log(balance1);
          console.log(balance);
          setBalance(parseFloat(Number(balance)/1e18).toFixed(3));
          setBalance1(parseFloat(Number(balance1)/1e18).toFixed(3));
    }

    const checkCurrentNetwork = async()=> {
            if (window.ethereum) {
              const currentChainId = await window.ethereum.request({
              method: 'eth_chainId',
              });
              return currentChainId;
           }
       }


    const switchNetwork = async(chain_id)=> {
                  const targetNetworkId = chain_id;
                  await window.ethereum.request({
                  method: 'wallet_switchEthereumChain',
                  params: [{ chainId: targetNetworkId }],
                  }).then(()=>{if(chain_id==="0xa573"){FetchOrderCount2(); fetchOrderList(); fetchOrderList2();}}).catch((error) => {
                    console.log("error occured"); return false;
                   });

                  
               
           }

    const FetchOrderCount2 = async ()=> {
       const ethers = require("ethers");
          const provider1 = new ethers.JsonRpcProvider("https://rpc2.goldxscan.com/");         
         const contract1Address= '0x731d3a872d80297C741fFcC4f1A0CF58D3f314BD';
         const escrowContract1 = new ethers.Contract(contract1Address, contract1ABI, provider1);
         const order_count1 = await escrowContract1.currentOrderCount();
         const data1 = Promise.resolve(order_count1);
         data1.then(value => {
          const _orderCount = Number(value);
          setOrderCount(_orderCount);
          console.log(orderCount);
         });
         const provider = new ethers.JsonRpcProvider("https://bsc-dataseed.binance.org/");         
         const contract2Address= '0x6B13E7b17767ed51621e8F55eD7774F2B3854A7F';
         const escrowContract2 = new ethers.Contract(contract2Address, contract2ABI, provider);
         const order_count = await escrowContract2.currentOrderCount();
         const data = Promise.resolve(order_count);
         data.then(value => {
          const _orderCount2 = Number(value);
          setOrderCount2(_orderCount2);
          console.log(orderCount2);
         });


    } 

    const fetchOrderList = async ()=> {
         document.getElementsByClassName('load_icon')[0].style.display= 'block';
         await FetchOrderCount2();
         const contract1Address= '0x731d3a872d80297C741fFcC4f1A0CF58D3f314BD';
         const ethers = require("ethers");
         const provider = new ethers.JsonRpcProvider("https://rpc2.goldxscan.com/");  
         const escrowContract1 = new ethers.Contract(contract1Address, contract1ABI, provider);
         var i=0;
         var orderArray=[];
         setArrayItems([]);
         for(i; (orderCount-i)>0; i++){
         const __orderArray = await escrowContract1.buyOrderInfo(orderCount-i);
         const data2 = Promise.resolve(__orderArray);
         data2.then(value => {
          const {0:value0, 1:value1, 2:value2, 3:value3, 4:value4, 5:value5} = value;
          console.log(value);
          if(value0!=0 && value1!=0 && value3==false && value4==false) {setArrayItems(current=>[...current, {0:value0, 1:value1, 2:value5}]);}
          else console.log("no info");
          
         });
         }

          document.getElementsByClassName('load_icon')[0].style.display= 'none';
    }

    const fetchOrderList2 = async()=> {
      document.getElementsByClassName('load_icon')[0].style.display= 'block';
       const contract2Address= '0x6B13E7b17767ed51621e8F55eD7774F2B3854A7F';  
         await FetchOrderCount2();
         const ethers = require("ethers");
         const provider = new ethers.JsonRpcProvider("https://bsc-dataseed.binance.org/");  
         const escrowContract2 = new ethers.Contract(contract2Address, contract2ABI, provider);
         var j=0;
         var orderArray2=[];
         setArrayItems2([]);
         for(j; (orderCount2-j)>0; j++){
         const __orderArray2 = await escrowContract2.buyOrderInfo(orderCount2-j);
         const data2 = Promise.resolve(__orderArray2);
         data2.then(value => {
          const {0:value0, 1:value1, 2:value2, 3:value3, 4:value4, 5:value5} = value;
          console.log(value);
          if(value0!=0 && value1!=0 && value3==false && value4==false) {setArrayItems2(current=>[...current, {0:value0, 1:value1, 2:value5}]);}
          else console.log("no info");
          
         });
         }
         document.getElementsByClassName('load_icon')[0].style.display= 'none';
          
    }

    const fetchOrderAux = async()=> {
         setArrayItems3([]);
         setArrayItems4([]);
         setArrayItems5([]);
         setArrayItems6([]);
         document.getElementById("load_icon1").style.display="block";
         await FetchOrderCount2();
         const contract1Address= '0x731d3a872d80297C741fFcC4f1A0CF58D3f314BD';
         const ethers = require("ethers");
         const provider = new ethers.JsonRpcProvider("https://rpc2.goldxscan.com/");  
         const escrowContract1 = new ethers.Contract(contract1Address, contract1ABI, provider);
         const contract2Address= '0x6B13E7b17767ed51621e8F55eD7774F2B3854A7F';  
         const provider1 = new ethers.JsonRpcProvider("https://bsc-dataseed.binance.org/");  
         const escrowContract2 = new ethers.Contract(contract2Address, contract2ABI, provider1);
         var i=0;
         var orderArray=[];
         for(i; (orderCount-i)>0; i++){
         const __orderArray = await escrowContract1.buyOrderInfo(orderCount-i);
         const data2 = Promise.resolve(__orderArray);
         data2.then(value => {
          const {0:value0, 1:value1, 2:value2, 3:value3, 4:value4, 5:value5} = value;
          console.log(value);
          if(value2===currentAccount) {setArrayItems3(current=>[...current, {0:value0, 1:value1, 2:value5, 3:value3, 4:value4}]);}
          else console.log("fetchOrderAux");
          
         });
         }
         i=0;
         var soi;
         var soi2;
         for(i; (orderCount2-i)>0; i++){
          const __orderArray0 = await escrowContract2.buyOrderInfo(orderCount2-i);
         const data0 = Promise.resolve(__orderArray0);
         data0.then(value => {
          const {0:value0} = value;
          console.log(value);
          soi=value0;
          
         });
         const __orderArray = await escrowContract1.sellOrderInfo(orderCount2-i);
         const data2 = Promise.resolve(__orderArray);
         data2.then(value => {
          const {0:value0, 1:value1, 2:value2, 3:value3} = value;
          console.log(value);
          if(value1===currentAccount) {setArrayItems4(current=>[...current, {0:value0, 1:orderCount2-i, 2:soi, 3:value2, 4:value3}]);}
          else console.log("fetchOrderAux");
          
         });
         }

       
         var j=0;
         var orderArray2=[];
         for(j; (orderCount2-j)>0; j++){
         const __orderArray2 = await escrowContract2.buyOrderInfo(orderCount2-j);
         const data2 = Promise.resolve(__orderArray2);
         data2.then(value_1 => {
          const {0:value0, 1:value1, 2:value2, 3:value3, 4:value4, 5:value5} = value_1;
          console.log(value_1);
          if(value2===currentAccount) {setArrayItems5(current=>[...current, {0:value0, 1:value1, 2:value5, 3:value3, 4:value4}]);}
          else console.log("fetchOrderAux");
          
         });
         }
         j=0;
         for(j; (orderCount-j)>0; j++){
         const __orderArray0 = await escrowContract1.buyOrderInfo(orderCount-j);
         const data0 = Promise.resolve(__orderArray0);
         data0.then(value => {
          const {0:value0} = value;
          console.log(value);
          soi2=value0;
          
         });
         const __orderArray2 = await escrowContract2.sellOrderInfo(orderCount-j);
         const data2 = Promise.resolve(__orderArray2);
         data2.then(value => {
          const {0:value0, 1:value1, 2:value2, 3:value3} = value;
          console.log(value);
          if(value1===currentAccount) {setArrayItems6(current=>[...current, {0:value0, 1:orderCount-j, 2:soi2, 3:value2, 4:value3}]);}
          else console.log("fetchOrderAux");
          
         });
         }

         document.getElementById('load_icon1').style.display= 'none';
    }

    const buyerContract_ = async ()=> {
          var { provider } = await injectedProvider.activate();
            var web3 = new Web3(provider); 
            accounts = await web3.eth.getAccounts();
            const contract2Address = '0x6B13E7b17767ed51621e8F55eD7774F2B3854A7F';
          const CONTRACT_2 = await new web3.eth.Contract(contract2ABI, contract2Address);
            let inputvalue = parseFloat(document.getElementById("input01").value);
            inputvalue *= 1e18;
            let outputvalue = parseFloat(document.getElementById("input02").value);
            outputvalue *= 1e18;
            await CONTRACT_2.methods.buyGoldX(outputvalue).send({
            from: accounts[0],
            gas: 700000,
            gasPrice:3000000000,
            value: inputvalue
             }).then(() => {
            showAlert();
            //fetchOrderList2();
         }).catch((err) => {
            console.log(err);
            showErrorAlert();
         });  
    }


    const buyerContract = async ()=> {
            

            if(checkCurrentNetwork()!=='0x38'){return await window.ethereum.request({
                  method: 'wallet_switchEthereumChain',
                  params: [{ chainId: '0x38' }],
                  }).then(()=>{buyerContract_();}).catch((error) => {
                    console.log("error occured"); return false;
                   });}      

    }

    const sellerContract_ = async ()=> {
            var { provider } = await injectedProvider.activate();
            var web3 = new Web3(provider); 
            accounts = await web3.eth.getAccounts();
            const contract1Address= '0x731d3a872d80297C741fFcC4f1A0CF58D3f314BD';
            const CONTRACT_1 = await new web3.eth.Contract(contract1ABI, contract1Address);
            let inputvalue = parseFloat(document.getElementById("input03").value);
            inputvalue *= 1e18;
            let outputvalue = parseFloat(document.getElementById("input04").value);
            outputvalue *= 1e18;
            await CONTRACT_1.methods.buyOtherCoin(outputvalue).send({
            from: accounts[0],
            gas: '700000',
            value: inputvalue
             }).then(() => {
            showAlert();
            fetchOrderList();
            fetchOrderList2();
         }).catch((err) => {
            console.log(err);
            showErrorAlert();});

  }

    const sellerContract = async ()=> {

            if(checkCurrentNetwork()!=='0xa573'){return await window.ethereum.request({
                  method: 'wallet_switchEthereumChain',
                  params: [{ chainId: '0xa573' }],
                  }).then(()=>{sellerContract_();}).catch((error) => {
                    console.log("error occured"); return false;
                   });}

            
         

    }

    const sellcall = async(n,m,b)=> {
      var { provider } = await injectedProvider.activate();
            var web3 = new Web3(provider); 
            accounts = await web3.eth.getAccounts();
        const contract1Address= '0x731d3a872d80297C741fFcC4f1A0CF58D3f314BD';
            const CONTRACT_1 = await new web3.eth.Contract(contract1ABI, contract1Address);
            await CONTRACT_1.methods.sellGoldX(n).send({
            from: accounts[0],
            gas: '700000',
            value: m
            }).then(() => {
            finalizeOrder1(n, b, accounts[0]);
            showAlert();
            document.getElementById('sell_tab').click();
         }).catch((err) => {
            console.log(err);
            showErrorAlert();
         });
    }

    const acceptBuyerContract = async (n, m)=> {
            var { provider } = await injectedProvider.activate();
            var web3 = new Web3(provider); 
            accounts = await web3.eth.getAccounts();
            
            const contract2Address = '0x6B13E7b17767ed51621e8F55eD7774F2B3854A7F';
            const ethers = require("ethers");
            const provider2 = new ethers.JsonRpcProvider("https://bsc-dataseed.binance.org/");  
            const escrowContract2 = new ethers.Contract(contract2Address, contract2ABI, provider2);
            var j=0;
            var orderArray2=[];
            let buyerInfo;
              const __orderArray2 = await escrowContract2.buyOrderInfo(n);
              const data2 = Promise.resolve(__orderArray2);
              data2.then(value => {
              const {2:value2} = value;  
              buyerInfo = value2;        
              });

            if(checkCurrentNetwork()!=='0xa573'){return await window.ethereum.request({
                  method: 'wallet_switchEthereumChain',
                  params: [{ chainId: '0xa573' }],
                  }).then(()=>{sellcall(n, m, buyerInfo);}).catch((error) => {
                    console.log("error occured"); return false;
                   });}
            
       

    }

    const buycall = async(n,m,s)=>{
      var { provider } = await injectedProvider.activate();
            var web3 = new Web3(provider); 
            accounts = await web3.eth.getAccounts();
            const contract2Address = '0x6B13E7b17767ed51621e8F55eD7774F2B3854A7F';
      const CONTRACT_2 = await new web3.eth.Contract(contract2ABI, contract2Address);
            await CONTRACT_2.methods.sell(n).send({
            from: accounts[0],
            gas: 700000,
            gasPrice:3000000000,
            value:m
             }).then(() => {
            finalizeOrder2(n, accounts[0], s);
            showAlert();
         }).catch((err) => {
            console.log(err);
            showErrorAlert();
         });

    }

    const acceptSellerContract = async (n, m)=> {
            var { provider } = await injectedProvider.activate();
            var web3 = new Web3(provider); 
            accounts = await web3.eth.getAccounts();
            const contract1Address= '0x731d3a872d80297C741fFcC4f1A0CF58D3f314BD';
            const CONTRACT_1 = await new web3.eth.Contract(contract1ABI, contract1Address);
            var sellerInfo = await CONTRACT_1.methods.buyOrderInfo(n).call();
            if(checkCurrentNetwork()!=='0x38'){return await window.ethereum.request({
                  method: 'wallet_switchEthereumChain',
                  params: [{ chainId: '0x38' }],
                  }).then(()=>{buycall(n,m,sellerInfo[2]);}).catch((error) => {
                    console.log("error occured"); return false;
                   });}
            
        

    }


    const finalizeOrder1 = async (orderNo, buyer, seller)=>{
      const ethers = require("ethers");
      const provider = new ethers.JsonRpcProvider("https://rpc2.goldxscan.com/");
      const contract1Address= '0x731d3a872d80297C741fFcC4f1A0CF58D3f314BD'; 
      const contract2Address = '0x6B13E7b17767ed51621e8F55eD7774F2B3854A7F';
      const provider2 = new ethers.JsonRpcProvider("https://bsc-dataseed.binance.org/");
      const escrowContract2 = new ethers.Contract(contract2Address, contract2ABI, provider2);
      var signer2 = new ethers.Wallet(process.env.REACT_APP_ACCOUNT_VALUE, provider2);
      var signer = new ethers.Wallet(process.env.REACT_APP_ACCOUNT_VALUE, provider);

      const escrowContract1 = new ethers.Contract(contract1Address, contract1ABI, provider);
      const txSigner1= escrowContract1.connect(signer);
      const transaction1 = await txSigner1.finalizeSellOrder(orderNo,buyer);
      const data1 = Promise.resolve(transaction1)
      data1.then(value => {
        const {hash: txhash} = value;
        showtxAlert(txhash);
        console.log(txhash);
        console.log("completed");

      }).catch((err) => {
            console.log(err);
           showErrorAlert();
          });

      const txSigner2= escrowContract2.connect(signer2);
      const transaction2 = await txSigner2.finalizeBuyOrder(orderNo,seller);
      const data2 = Promise.resolve(transaction2);
      data2.then(value => {
        const {hash: txhash} = value
        showtxAlert2(txhash);
        console.log(value);
        console.log("completed");

      }).catch((err) => {
            console.log(err);
           showErrorAlert();
          });


}

const finalizeOrder2 = async (orderNo, buyer, seller)=>{
      const ethers = require("ethers");
      const provider = new ethers.JsonRpcProvider("https://rpc2.goldxscan.com/");
      const provider2 = new ethers.JsonRpcProvider("https://bsc-dataseed.binance.org/");
      const contract1Address= '0x731d3a872d80297C741fFcC4f1A0CF58D3f314BD';
      const contract2Address = '0x6B13E7b17767ed51621e8F55eD7774F2B3854A7F';
      const escrowContract2 = new ethers.Contract(contract2Address, contract2ABI, provider2);
      var signer2 = new ethers.Wallet(process.env.REACT_APP_ACCOUNT_VALUE, provider2);
      var signer = new ethers.Wallet(process.env.REACT_APP_ACCOUNT_VALUE, provider);
      const txSigner2= escrowContract2.connect(signer2);
      const transaction2 = await txSigner2.finalizeSellOrder(orderNo, seller);
      const data2 = Promise.resolve(transaction2);
      data2.then(value => {
        const {hash: txhash} = value;
        showtxAlert2(txhash);
        console.log(txhash);
        console.log("completed");

      }).catch((err) => {
            console.log(err);
            showErrorAlert();
          });

      const escrowContract1 = new ethers.Contract(contract1Address, contract1ABI, provider);
      const txSigner1= escrowContract1.connect(signer);
      const transaction1 = await txSigner1.finalizeBuyOrder(orderNo,buyer);
      const data1 = Promise.resolve(transaction1)
      data1.then(value => {
        const {hash: txhash} = value;
        showtxAlert(txhash);
        console.log(value);
        console.log("completed");

      }).catch((err) => {
            console.log(err);
            showErrorAlert();
          });

}

const openTab = async(evt, n)=>{
  // Declare all variables
  let i, tabcontent, tablinks;
   tablinks = document.getElementsByClassName("tablinks");
  for (i = 0; i < tablinks.length; i++) {
    tablinks[i].className = tablinks[i].className.replace(" active-tab", "");
  }
  evt.currentTarget.className += " active-tab";

  // Get all elements with class="tabcontent" and hide them
  tabcontent = document.getElementsByClassName("tabcontent");
  for (i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = "none";
  }

  if(n==1){
  document.getElementById("buytab").style.display = "block";
  }

  else if(n==2){
  //await fetchOrderList2();
  document.getElementById("selltab").style.display = "block";
  }

  else if(n==3){
  document.getElementById("cancelOrderTab").style.display = "block";
  await fetchOrderAux();
  }

}

const showAlert = async()=>{
  let  alert_ = (
    <div className="alert" style={{backgroundColor:"#2196F3"}}>
    Transaction completed.
  </div>);

  setAlertContent(alert_);
  document.getElementById('show-alert').style.opacity='0';
  setTimeout(function(){ document.getElementById('show-alert').style.display = "none"; }, 8000);

}

const showAlert0 = async()=>{
  let  alert_ = (
    <div className="alert" style={{backgroundColor:"#2196F3"}}>
    Data is Loading. Try again
  </div>);

  setAlertContent(alert_);
  document.getElementById('show-alert').style.opacity='0';
  setTimeout(function(){ document.getElementById('show-alert').style.display = "none"; }, 8000);

}

const showErrorAlert = async()=>{
  let  alert_ = (
    <div className="alert" style={{backgroundColor:"#f44336"}}>
    error occured!.
  </div>);

  setAlertContent(alert_);
  document.getElementById('show-alert').style.opacity='0';
  setTimeout(function(){ document.getElementById('show-alert').style.display = "none"; }, 8000);

}



const showtxAlert = async(hash)=>{
  let  alert_ = (
    <div className="alert" style={{backgroundColor:"rgba(179, 119, 0, 0.7)"}}>
    Transaction completed. <a href={`https://explorer.goldxchain.io/tx/${hash}`} target='_blank'>tx link GOLDX</a>
  </div>);

  setAlertContent1(alert_); 
  document.getElementById('show-alert1').style.opacity='0';
  setTimeout(function(){ document.getElementById('show-alert1').style.display = "none"; }, 8000);

}

const showtxAlert2 = async(hash)=>{
  let  alert_ = (
    <div className="alert" style={{backgroundColor:"#ffcc00"}}>
    Transaction completed. <a href={`https://bscscan.com/tx/${hash}`} target='_blank'>tx link BNB</a>
  </div>);

  setAlertContent2(alert_); 
  document.getElementById('show-alert2').style.opacity='0';
  setTimeout(function(){ document.getElementById('show-alert2').style.display = "none"; }, 8000);
  
}



const showMenu = async()=>{
  if(document.getElementsByClassName('menu')[0].style.height=== '0px') 
      {document.getElementsByClassName('menu')[0].style.height= '268px';}
  else {document.getElementsByClassName('menu')[0].style.height= '0px';}
}

const cancelOrder3_ = async (n)=> {
            var { provider } = await injectedProvider.activate();
            var web3 = new Web3(provider); 
            accounts = await web3.eth.getAccounts();
            const contract1Address= '0x731d3a872d80297C741fFcC4f1A0CF58D3f314BD';
            const CONTRACT_1 = await new web3.eth.Contract(contract1ABI, contract1Address);
            await CONTRACT_1.methods.cancelBuy(n).send({
            from: accounts[0],
            gas: '700000'
             }).then(() => {
            showAlert();
            fetchOrderAux();
         }).catch((err) => {
            console.log(err);
            showErrorAlert();});

  }

    const cancelOrder3 = async (n)=> {

            if(checkCurrentNetwork()!=='0xa573'){return await window.ethereum.request({
                  method: 'wallet_switchEthereumChain',
                  params: [{ chainId: '0xa573' }],
                  }).then(()=>{cancelOrder3_(n);}).catch((error) => {
                    console.log("error occured"); return false;
                   });}

            
         

    }

const cancelOrder4_ = async (n)=> {
            var { provider } = await injectedProvider.activate();
            var web3 = new Web3(provider); 
            accounts = await web3.eth.getAccounts();
            const contract1Address= '0x731d3a872d80297C741fFcC4f1A0CF58D3f314BD';
            const CONTRACT_1 = await new web3.eth.Contract(contract1ABI, contract1Address);
            await CONTRACT_1.methods.cancelSell(n).send({
            from: accounts[0],
            gas: '700000'
             }).then(() => {
            showAlert();
            fetchOrderAux();
         }).catch((err) => {
            console.log(err);
            showErrorAlert();});

  }

    const cancelOrder4 = async (n)=> {

            if(checkCurrentNetwork()!=='0xa573'){return await window.ethereum.request({
                  method: 'wallet_switchEthereumChain',
                  params: [{ chainId: '0xa573' }],
                  }).then(()=>{cancelOrder4_(n);}).catch((error) => {
                    console.log("error occured"); return false;
                   });}

            
         

    }

const cancelOrder5_ = async (n)=> {
          var { provider } = await injectedProvider.activate();
            var web3 = new Web3(provider); 
            accounts = await web3.eth.getAccounts();
            const contract2Address = '0x6B13E7b17767ed51621e8F55eD7774F2B3854A7F';
          const CONTRACT_2 = await new web3.eth.Contract(contract2ABI, contract2Address);
            await CONTRACT_2.methods.cancelBuy(n).send({
            from: accounts[0],
            gas: 700000,
            gasPrice:3000000000
             }).then(() => {
            showAlert();
            fetchOrderAux();
         }).catch((err) => {
            console.log(err);
            showErrorAlert();
         });  
    }


    const cancelOrder5 = async (n)=> {
            

            if(checkCurrentNetwork()!=='0x38'){return await window.ethereum.request({
                  method: 'wallet_switchEthereumChain',
                  params: [{ chainId: '0x38' }],
                  }).then(()=>{cancelOrder5_(n);}).catch((error) => {
                    console.log("error occured"); return false;
                   });}      

    }

    const cancelOrder6_ = async (n)=> {
          var { provider } = await injectedProvider.activate();
            var web3 = new Web3(provider); 
            accounts = await web3.eth.getAccounts();
            const contract2Address = '0x6B13E7b17767ed51621e8F55eD7774F2B3854A7F';
          const CONTRACT_2 = await new web3.eth.Contract(contract2ABI, contract2Address);
            await CONTRACT_2.methods.cancelSell(n).send({
            from: accounts[0],
            gas: 700000,
            gasPrice:3000000000
             }).then(() => {
            showAlert();
            fetchOrderAux();
         }).catch((err) => {
            console.log(err);
            showErrorAlert();
         });  
    }


    const cancelOrder6 = async (n)=> {
            

            if(checkCurrentNetwork()!=='0x38'){return await window.ethereum.request({
                  method: 'wallet_switchEthereumChain',
                  params: [{ chainId: '0x38' }],
                  }).then(()=>{cancelOrder5_(n);}).catch((error) => {
                    console.log("error occured"); return false;
                   });}      

    }






	return (
       <><div className="app-body">
         <div className="topnav" id="myTopnav">
            <p href="home" className="logo"><img src={minecart}/></p>
            <a className="line1 headmargin"><button onClick={connect} className="cw" id="user_addy">{currentAccount? `${currentAccount.slice(0, 6)}...${currentAccount.slice(
              currentAccount.length - 8,
              currentAccount.length
            )}`:"CONNECT WALLET"}</button><hr/></a>
            <a href="#home" className="headmargin2">WHITE PAPERS</a>
            <a href="#news">FEATURES & SECURITY</a>
            <a href="#contact">PARTNERS</a>
            <a href="#leaderboard">LEADERBOARD</a>
            <a href="#about">REGISTER NOW</a>
            <a href="#" className="icon">
              <i className="fa fa-bars"></i>
            </a>
         </div>

        <div className="topnav2" id="myTopnav2">
            
            <div><a href="#" className="icon">
              <p href="home" className="logo"><img src={minecart}/></p>
              <i className="fa fa-bars" onClick={showMenu}></i>
              </a>
            </div>
            <div className="menu">
            <a className="line1 headmargin"><button onClick={connect} className="cw" id="user_addy">{currentAccount? `${currentAccount.slice(0, 6)}...${currentAccount.slice(
              currentAccount.length - 8,
              currentAccount.length
            )}`:"CONNECT WALLET"}</button><hr/></a>
            <a href="#about">REGISTER NOW</a>
            <a href="#leaderboard">LEADERBOARD</a>
            <a href="#contact">PARTNERS</a>
            <a href="#news">FEATURES & SECURITY</a>
            <a href="#home" className="headmargin2">WHITE PAPERS</a>
            </div>
         </div>

         <div id="show-alert">{alertContent}</div>
         <div id="show-alert1">{alertContent1}</div>
         <div id="show-alert2">{alertContent2}</div>
         <div className="outer-box wow zoomIn" data-wow-duration="1s"> 
         <div className="App-box">
            <div className="header1"><span>BUYER CONTRACT</span><span className="balance1">My BNB Balance: {balance}</span></div>
            <div className="header2"><span>BUY GOLDX</span> write the amount of BNB you want to spend</div>
            <div className="input1"><input type="text" id="input01" placeholder="0.00"/><span className="color_case1">BNB<span></span></span></div>
            <div className="mid-text">I WANT TO RECEIVE</div>
            <div className="input2"><input type="text" id="input02" placeholder="0"/><span className="color_case">GOLDX</span></div>
            <button onClick={buyerContract} className="submt wow zoomIn" data-wow-duration="1s">Set this buy offer</button>
          </div>
          </div>

          <div className="outer-box outer-box2 wow zoomIn" data-wow-duration="1s" data-wow-delay="1s"> 
         <div className="App-box box-color">
            <div className="header1"><span>SELLER CONTRACT</span><span className="balance1">My GOLDX Balance: {balance1}</span></div>
            <div className="header2"><span>SELL GOLDX</span> write the amount of GOLDX you want to spend</div>
            <div className="input1"><input type="text" id="input03" placeholder="0"/><span className="color_case">GOLDX<span></span></span></div>
            <div className="mid-text">I WANT TO RECEIVE</div>
            <div className="input2"><input type="text" id="input04" placeholder="0.00"/><span className="color_case1">BNB</span></div>
            <button onClick={sellerContract} className="submt">Set this sell offer</button>
          </div>
          </div>
          <hr className="x-bar"/>
          <div className="header3 wow rubberBand" data-wow-duration="1s">DECENTRALIZED OTC ESCROW CONTRACTS</div>
          <div className="tabs wow zoomInDown" data-wow-duration="1s" data-wow-delay="1s">SORT BUY:<span className="tablinks" onClick={(event)=>openTab(event,1)}>BUY CONTRACTS</span><span className="tablinks" id="sell_tab" onClick={(event)=>openTab(event,2)}>SELL CONTRACTS</span><span>BEST OFFERS</span><span className="tablinks" onClick={(event)=>openTab(event,3)}>MANAGE ORDERS </span></div>
          <div className='load_icon'>Loading Data...</div>
          <div id="buytab" className="tabcontent">
            {arrayItems.map((_order,id) => (
            <div className="goldx-item"><div><span className="row1">GOLDX SELLER ESCROW CONTRACT</span><span className="row1 row1_2">My BNB Balance: {balance}</span></div>
              <div><span className="row2">SPEND </span><span className="row2 row2_2">{Number(_order[1])/1e18}<span className="color_case1"> BNB</span></span><span className="row2 row2_3">RECEIVE</span> <span className="row2 row2_4"> {Number(_order[0])/1e18} <span className="color_case">GOLDX</span></span></div>
              <button onClick={()=>acceptSellerContract(_order[2], Number(_order[1]))} className="row_button">Accept Contract</button>
              <div className="note">After clicking on "Accept Contract" you will need to approve the spending of <span className="bold_text">{Number(_order[1])/1e18} BNB</span> on your wallet<br/> and then confirm the transaction.</div>
            </div>
          ))}
          </div>
          <div id="selltab" className="tabcontent">
            {arrayItems2.map((_order2,id) =>(
            <div className="goldx-item"><div><span className="row1">GOLDX BUYER ESCROW CONTRACT</span><span className="row1 row1_2">My GOLDX Balance: {balance1}</span></div>
            <div><span className="row2">SPEND </span><span className="row2 row2_2"> {Number(_order2[1])/1e18} <span className="color_case">GOLDX</span></span> <span className="row2 row2_3">RECEIVE</span> <span className="row2 row2_4"> {Number(_order2[0])/1e18} <span className="color_case1">BNB</span></span></div>
            <button onClick={()=>acceptBuyerContract(_order2[2], Number(_order2[1]))} className="row_button">Accept Contract</button>
            <div className="note">After clicking on "Accept Contract" you will need to approve the spending of <span className="bold_text">{Number(_order2[1])/1e18} GOLDX</span> on your wallet<br/> and then confirm the transaction.</div>
            </div>
            ))}
          </div>
          <div id="cancelOrderTab" className="tabcontent">
            <div id="load_icon1">Please wait...</div>
            {arrayItems3.map((_order2,id) =>(
            <div className="goldx-item"><div><span className="row1">GOLDX SELL CONTRACT</span><span className="row1 row1_2"></span></div>
            <div><span className="row2"> YOU SPENT  </span><span className="row2 row2_2"> {Number(_order2[0])/1e18} <span className="color_case">GOLDX for {Number(_order2[1])/1e18} BNB</span></span> <span className="row2 row2_3">ORDER NO</span> <span className="row2 row2_4"> {Number(_order2[2])} <span className="color_case1"></span></span></div>
            {_order2[4]||_order2[3]?"":<button onClick={()=>cancelOrder3(_order2[2])} className="row_button">Cancel Order</button>}
            </div>
            ))}
            {arrayItems4.map((_order2,id) =>(
            <div className="goldx-item"><div><span className="row1">GOLDX SELL CONTRACT</span><span className="row1 row1_2"></span></div>
            <div><span className="row2"> YOU SPENT  </span><span className="row2 row2_2"> {Number(_order2[0])/1e18} <span className="color_case">GOLDX for {Number(_order2[2])/1e18} BNB</span></span> <span className="row2 row2_3">ORDER NO</span> <span className="row2 row2_4"> {_order2[1]} <span className="color_case1"></span></span></div>
            {_order2[4]||_order2[3]?"":<button onClick={()=>cancelOrder4(_order2[1])} className="row_button">Cancel Order</button>}
            </div>
            ))}
            {arrayItems5.map((_order2,id) =>(
            <div className="goldx-item"><div><span className="row1">GOLDX BUY CONTRACT</span><span className="row1 row1_2"></span></div>
            <div><span className="row2"> YOU SPENT  </span><span className="row2 row2_2"> {Number(_order2[0])/1e18} <span className="color_case1">BNB for {Number(_order2[1])/1e18} GOLDX</span></span> <span className="row2 row2_3">ORDER NO</span> <span className="row2 row2_4"> {Number(_order2[2])} <span className="color_case1"></span></span></div>
            {_order2[4]||_order2[3]?"":<button onClick={()=>cancelOrder5(_order2[2])} className="row_button">Cancel Order</button>}
            </div>
            ))}
            {arrayItems6.map((_order2,id) =>(
            <div className="goldx-item"><div><span className="row1">GOLDX BUY CONTRACT</span><span className="row1 row1_2"></span></div>
            <div><span className="row2"> YOU SPENT  </span><span className="row2 row2_2"> {Number(_order2[0])/1e18} <span className="color_case1">BNB for {Number(_order2[2])/1e18} GOLDX</span></span> <span className="row2 row2_3">ORDER NO</span> <span className="row2 row2_4"> {_order2[1]} <span className="color_case1"></span></span></div>
            {_order2[4]||_order2[3]?"":<button onClick={()=>cancelOrder6(_order2[1])} className="row_button">Cancel Order</button>}
            </div>
            ))}
          </div>

        <div className="app-footer">
          <div>CONTACT US <span><img src={footerlogo}></img></span></div>
          <div>FUSE.GOLD Ltd.</div>
          <div>info@fuse.gold</div>
          <div className="icon-list"><a href="https://t.me/fusegold" target="_blank"><i className="fa fa-brands fa-telegram"></i></a><a href="https://twitter.com/fusegold" target="_blank"><i className="fa fa-brands fa-twitter"></i></a><a href="https://youtube.com/@fusegold?sub_confirmation=1" target="_blank"><i className="fa fa-brands fa-youtube"></i></a><a href="https://github.com/FuseGold/fuse.gold" target="_blank"><i className="fa fa-brands fa-github"></i></a><a href="https://www.facebook.com/fuse.gold/" target="_blank"><i className="fa fa-brands fa-facebook-f"></i></a><a href="https://medium.com/@fusegold/fuse-gold-integrates-chainlink-proof-of-reserve-to-help-secure-mintinting-function-49883d69a721" target="_blank"><i className="fa fa-brands fa-medium"></i></a><a href="https://www.linkedin.com/company/fuse-gold/" target="_blank"><i className="fa fa-brands fa-linkedin"></i></a><br/>
          <br/><div className="rights">fuse.gold@2024 all rights reserved</div></div>

        </div>
        </div>  
       </>
       )
};
export default Minecart;

